
import _ from 'lodash';
import Imm from 'immutable';
import React from 'react';

export const entityType = instance => Object.getPrototypeOf(instance).constructor;

// Utility function to use in combination with the "function-bind" operator (::). Used to
// pipe a value to a function, using a postfix notation rather than the usual `fn(arg)`.
// Example: `42::pipe(x => x + 1) === 43`
export const pipe = function(fn) { // Regular function, since we're accessing `this`
    return fn(this);
};


// Update a value at path, returning the (new) updated object
export const updateIn = (object, path, value) => {
    return _.set(_.cloneDeep(object), path, value);
};


export const mapToElements = function(fn) {
    if (!(this instanceof Imm.Map)) {
        throw new Error('Expected Immutable.Map');
    }
    
    return this
        .map((item, index) => {
            const reactElement = fn(item, index);
            if (!reactElement.props.hasOwnProperty('key')) {
                return React.cloneElement(reactElement, { key: index });
            } else {
                return reactElement;
            }
        })
        .toArray();
};
